import React, { useState } from 'react'


export default function Footer() {

  return (
    <div className={`footer`}>
        <p>© 2025 Rafael Merlotto</p>
        {/* <a href="https://icons8.com/">icons8</a> */}
    </div>
  )
}
